import React from "react";
import { graphql } from "gatsby";
import PageLayout from "./pageLayout";
import { CarouselImages } from "./carouselImages";
import { isMobile } from "../utils/isMobile";
import { Description } from "./description";
import ImageGrid from "./imageGrid";
import { InfoProject } from "./info";

export default function ProjectTemplate2({ data }) {
  let { title, description, tags, meta } = data.content;

  let carouselIndicators = true;

  if (isMobile()) {
    carouselIndicators = false;
  }

  let images = data.allFile.group.map((group) => group);

  let titleImage = images[0].nodes;

  images.shift();

  return (
    <PageLayout title={title}>
      <div className="d-flex justify-content-center mt-5">
        <div className={`container mr-2 mr-sm-4 ml-2 ml-sm-4`}>
          <CarouselImages index={0} images={titleImage} paragraph={null} showIndicators={carouselIndicators} />
          <InfoProject title={title} meta={meta} tags={tags.join(", ")}/>
          <Description description={description} />
          <br />
          <div className="mt-3">
            <ImageGrid images={images[0].nodes} />
          </div>
        </div>
      </div>
      <br />
    </PageLayout>
  );
}

export const query = graphql`
  query($slug: String!, $carouselDir: String!) {
    content(fields: { slug: { eq: $slug } }) {
      title
      description {
        childMdx {
          body
        }
      }
      tags
      meta
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativeDirectory: { regex: $carouselDir }
      }
      sort: { fields: name }
    ) {
      group(field: relativeDirectory) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

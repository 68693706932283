import React from "react";
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx";
import mdxComponents from "./mdxComponents";

export const Description = (props) => {
  let { description } = props;

  if (!description){
    return null;
  }

  return (
    <MDXProvider components={mdxComponents}>
    <div className="row mt-5">
      <div className="col-sm-3" />
      <div className="mt-3 col-sm-9" style={{fontSize: "1.3rem"}}>
        <MDXRenderer>{description?.childMdx.body}</MDXRenderer>
      </div>
    </div>
    </MDXProvider>
  );
};

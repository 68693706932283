import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { ClickCarousel } from "./clickCarousel";

export const CarouselImages = (props) => {
  const { images, index, paragraph, className = "mb-5" } = props;

  let width = "12";

  let float = "";

  if (index !== 0 && index % 2 === 1) {
    width = "9";
    float = "text-right";
  } else if (index !== 0 && index % 2 === 0) {
    width = "9";
    float = "text-left";
  }

  let image = null;

  if (images.length === 1) {
    image = (
      <GatsbyImage image={images[0].childImageSharp.gatsbyImageData} alt={images[0].name} className="d-block w-100" />
    );
  }

  return (
    <div className={className}>
      <div className="row">
        {float === "text-right" ? <div className={"col-sm-3"} /> : null}
        <div className={`col-sm-${width}`}>{image ? image : <ClickCarousel images={images} />}</div>
        {float === "text-left" ? <div className={"col-sm-3"} /> : null}
      </div>

      <Description index={index} float={float} width={width} paragraph={paragraph} />
    </div>
  );
};

const Description = (props) => {
  let { index, float, width, paragraph } = props;

  if (index === 0) {
    return null;
  }

  return (
    <div className={`row mt-5`}>
      {float === "text-right" ? <div className={"col-sm-3"} /> : null}
      <div className={`col-sm-${width}`} style={{fontSize: "1.3rem"}}>
        {paragraph ? <MDXRenderer>{paragraph.childrenMdx[0].body}</MDXRenderer> : null}
      </div>
      {float === "text-left" ? <div className={"col-sm-3"} /> : null}
    </div>
  );
};

import React from "react";

export const MetaTags = (props) => {
  let { className, tags, separator } = props;

  if (!tags) {
    return null;
  }
  tags = tags.map((tag, index) => <MetaTag key={index} tag={tag} separator={separator} />);
  return <div className={className}>{tags}</div>;
};

const MetaTag = (props) => {
  let { tag, separator = ":" } = props;

  let [key, value] = tag.split(separator);

  return (
    <>
      <div className="row">
        <div className="col-6 col-sm-3 col-lg-2 medium">
          {key}{key?":":""}
        </div>
        <div className="col-6 col-sm-9 col-lg-10">{value}</div>
      </div>
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
    </>
  );
};

import React from "react";
import { MetaTags } from "./metaTags";
import { ErrorBoundary } from "./error";

export const InfoShop = (props) => {
  let { title, price, meta } = props;

  return (
    <ErrorBoundary>
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <div className="medium">{title}</div>
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <div style={{ color: "#B3B3B3" }}>{price}</div>
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <div style={{ height: "3rem" }} />
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <MetaTags tags={meta} />
    </ErrorBoundary>
  );
};

export const InfoProject = (props) => {
  let { title, meta, tags } = props;

  return (
    <ErrorBoundary>
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <div className="medium">{title}</div>
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <div style={{ color: "#B3B3B3" }}>{tags}</div>
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <div style={{ height: "3rem" }} />
      <hr className="p-0 m-0 bg-dark col-sm-5 line-color" />
      <MetaTags tags={meta} />
    </ErrorBoundary>
  );
};


const Title = (props) => {
  let { title } = props;

  return (
    <div className="row">
      <div className="col-6 col-sm-3 col-lg-2">
        <b>title:</b>
      </div>
      <div className="col-6 col-sm-9 col-lg-10">{title}</div>
    </div>
  );
};

const Price = (props) => {
  let { price } = props;

  return (
    <div className="row">
      <div className="col-6 col-sm-3 col-lg-2">
        <b>price:</b>
      </div>
      <div className="col-6 col-sm-9 col-lg-10">{price}</div>
    </div>
  );
};

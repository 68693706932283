const userAgent = () => {
  if (typeof navigator !== "undefined"){
    return navigator.userAgent;
  }
  else {
    return "null"
  }
}

export const isMobile = () => {
  return Boolean(userAgent().match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  ));
};

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { ErrorBoundary } from "./error";

export const ClickCarousel = (props) => {
  const { images } = props;

  const [imageIndex, setIndex] = React.useState(0);

  return (
    <ErrorBoundary>
      <div onClick={() => setIndex(imageIndex === images.length-1? 0 : imageIndex + 1)}>
        <GatsbyImage
          onClick={() => setIndex(imageIndex === images.length-1 ? 0 : imageIndex + 1)}
          image={images[imageIndex].childImageSharp.gatsbyImageData}
          alt={images[imageIndex].name}
          className="d-block w-100 click-carousel"
        />
      </div>
      <div className="mt-2">
        <CarouselIndex index={imageIndex} imageCount={images.length} />
      </div>
    </ErrorBoundary>
  );
};

const CarouselIndex = (props) => {
  let { index, imageCount } = props;

  return (
    <b>
      {index+1}/{imageCount}
    </b>
  );
};

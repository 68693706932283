import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageGrid = (props) => {
  let { images } = props;

  let gridImages = images.map((image, index) => (
    <div key={index} className={`mt-3 p-0 pr-2 pl-2 ${index % 3 === 0 ? "col-sm-12" : "col-sm-6"}`}>
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        alt={image.name}
        className={`d-block w-100`}
      />
    </div>
  ));

  return <div className="row">{gridImages}</div>;
};

export default ImageGrid;
